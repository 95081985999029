<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <dx-toolbar class="toolbar-details theme-dependent">
      <dxi-item location="before">
        <dx-button backButton [fallbackRoute]="'/partenaires'" stylingMode="text" icon="back"></dx-button>
      </dxi-item>
      <dxi-item location="before"
        text="{{!isCreation ? partenaire?.codeBo : 'Création'}} - {{!isCreation ? partenaire?.intitule : this.partenaireCategorieIntitule}}">
      </dxi-item>
      <dxi-item location="after">
        <dx-drop-down-button stylingMode="text" [useSelectMode]="true" [dataSource]="viewList"
          [dropDownOptions]="{ width: 'auto' }" keyExpr="id" displayExpr="intitule" [(selectedItemKey)]="selectedViewId"
          label="Vue" labelMode="static"
          >
        </dx-drop-down-button>
      </dxi-item>
      <dxi-item location="after" locateInMenu="auto">
        <div class="separator"></div>
      </dxi-item>
      <dxi-item location="after" locateInMenu="auto" widget="dxButton" showText="inMenu" [options]="{
          text: 'Copier',
          icon: 'copy',
          onClick: onCopyClick,
          stylingMode: 'text'
        }">
      </dxi-item>
      <dxi-item location="after" locateInMenu="auto">
        <div class="separator"></div>
      </dxi-item>
      <dxi-item location="after" [ratio]="1" *ngIf="!isCreation">
        <div class="menu-button">
          <dx-drop-down-button [items]="actions" keyExpr="id" displayExpr="intitule"
            (onItemClick)="onActionClick($event)" [disabled]="isModificationEnCours" [dropDownOptions]="{ width: 120 }"
            text="Action" icon="smalliconslayout"></dx-drop-down-button>
        </div>
      </dxi-item>
    </dx-toolbar>
    <div class="panels theme-dependent">
      <div class="left">
        <div>
          <app-partenaire-form [partenaireData]="partenaire" [(isEditing)]="isEditing"
            [isLoading]="isLoading"></app-partenaire-form>
        </div>
      </div>
      <div class="right theme-dependent">
        <div class="dx-card details-card">
          <dx-tab-panel secondary="secondary" [focusStateEnabled]="false" [showNavButtons]="true">
            <!-- Général -->
            <dxi-item title="Général" *ngIf="selectedViewId === ViewType.General">
              <div *dxTemplate>
                <dx-form *ngIf="partenaire" class="form last plain-styled-form general-padding" [formData]="partenaire"
                labelMode="floating" [class.view-mode]="!isEditing" [readOnly]="!isEditing" [colCount]="4">
                <!-- Administratif -->
                <dxi-item itemType="group" caption="Administratif" [colSpan]="4" colCount="4">
                  <dxi-item dataField="raisonSociale" [label]="{ text: 'Raison Sociale' }" [colSpan]="2"
                    [editorOptions]="{ maxLength: 120 }"></dxi-item>
                  <dxi-item dataField="enseigne" [label]="{ text: 'Enseigne' }" [colSpan]="2"
                    [editorOptions]="{ maxLength: 100 }"></dxi-item>
                  <dxi-item dataField="formeJuridiqueId" [label]="{ text: 'Forme juridique' }" editorType="dxSelectBox"
                    [editorOptions]="{
                          dataSource: formeJuridiques,
                          displayExpr: 'intitule',
                          valueExpr: 'id',
                          showClearButton: true,
                        }"></dxi-item>
                  <dxi-item dataField="identifiantEntreprise" [label]="{ text: 'N° Tahiti' }"
                    [editorOptions]="{ maxLength: 20 }"></dxi-item>
                  <dxi-item dataField="identifiantCommercial" [label]="{ text: 'N° RC' }"
                    [editorOptions]="{ maxLength: 20 }"></dxi-item>
                  <dxi-item dataField="numeroTvaIntracommunautaire" [label]="{ text: 'N° TVA intracommunautaire' }"
                    [editorOptions]="{ maxLength: 20 }"></dxi-item>
                  <dxi-item dataField="representantLegal" [label]="{ text: 'Représentant légal' }"
                    [editorOptions]="{ maxLength: 100 }"></dxi-item>
                  <dxi-item dataField="nom" [label]="{ text: 'Nom' }" [editorOptions]="{ maxLength: 100 }"></dxi-item>
                  <dxi-item dataField="prenom" [label]="{ text: 'Prénom' }"
                    [editorOptions]="{ maxLength: 100 }"></dxi-item>
                  <dxi-item dataField="dateNaissance" [label]="{ text: 'Date de naissance'}" editorType="dxDateBox"
                    [editorOptions]="{displayFormat: 'dd/MM/yyyy', width: '170px', type: 'date'}"></dxi-item>
                  <dxi-item dataField="civilite" [label]="{text: 'Civilité'}" editorType="dxSelectBox" [editorOptions]="{
                          dataSource: civiliteType,
                          searchEnabled: true,
                          displayExpr: 'libelle',
                          valueExpr: 'id',
                          showClearButton: true,
                          searchExpr: ['libelle', 'codeBo'],
                          showDataBeforeSearch: true
                        }"></dxi-item>
                  <dxi-item dataField="adhesionOffresCommerciales" [label]="{visible: false}" editorType="dxCheckBox"
                    [editorOptions]="{text: 'Adhésion aux offres commerciales'}"></dxi-item>
                </dxi-item>
                <!-- Géographique -->
                <dxi-item itemType="group" caption="Adresse Géographique" [colSpan]="2" [colCount]="2">
                  <dxi-item dataField="paysId" [label]="{text: 'Pays'}" editorType="dxSelectBox" [editorOptions]="{
                      dataSource: paysDataSource,
                      searchEnabled: true,
                      displayExpr: 'intitule',
                      valueExpr: 'id',
                      showClearButton: true,
                      searchExpr: ['intitule', 'codeDouane', 'codeNumerique'],
                      showDataBeforeSearch: true
                    }"></dxi-item>
                  <dxi-item dataField="secteurGeographiqueId" [label]="{text: 'Secteur géographique'}"
                    editorType="dxSelectBox" [editorOptions]="{
                          dataSource: secteurGeographiqueDataSource,
                          searchEnabled: true,
                          displayExpr: 'intitule',
                          valueExpr: 'id',
                          showClearButton: true,
                          searchExpr: ['intitule', 'codeBo'],
                          showDataBeforeSearch: true
                        }"></dxi-item>

                  <dxi-item dataField="commune" [label]="{ text: 'Commune' }" [editorOptions]="{ maxLength: 120 }">
                    <dxi-validation-rule type="stringLength" [max]="120"></dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="ileId" [label]="{text: 'Île'}" editorType="dxSelectBox" [editorOptions]="{
                      dataSource: ileDataSource,
                      searchEnabled: true,
                      displayExpr: 'libelle',
                      valueExpr: 'id',
                      showClearButton: true,
                      searchExpr: ['libelle'],
                      showDataBeforeSearch: true
                    }"></dxi-item>
                  <dxi-item dataField="adresseGeographique1" [colSpan]="2" [label]="{ text: 'Adresse géographique' }"
                    [editorOptions]="{ maxLength: 80 }">
                    <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="adresseGeographique2" [colSpan]="2" [label]="{ text: 'Adresse géographique 2' }"
                    [editorOptions]="{ maxLength: 80 }">
                    <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="adresseGeographique3" [colSpan]="2" [label]="{ text: 'Adresse géographique 3' }"
                    [editorOptions]="{ maxLength: 80 }">
                    <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="informationsLivraison" editorType="dxTextArea"
                    [editorOptions]="{height: 125, maxLength: 255}" [label]="{text: 'Informations livraison'}"
                    [colCount]="2"></dxi-item>
                </dxi-item>
                <!-- Postale -->
                <dxi-item itemType="group" caption="Adresse Postale" [colSpan]="2" [colCount]="2">
                  <dxi-item dataField="adressePostale1" [colSpan]="2" [label]="{ text: 'Adresse postale' }"
                    [editorOptions]="{ maxLength: 80 }">
                    <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="adressePostale2" [colSpan]="2" [label]="{ text: 'Adresse postale 2' }"
                    [editorOptions]="{ maxLength: 80 }">
                    <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="adressePostale3" [colSpan]="2" [label]="{ text: 'Adresse postale 3' }"
                    [editorOptions]="{ maxLength: 80 }">
                    <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="codePostal" [label]="{ text: 'Code postal' }"
                    [editorOptions]="{ maxLength: 12 }">
                    <dxi-validation-rule type="stringLength" [max]="12"></dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="boitePostale" [label]="{ text: 'Boîte postale' }"
                    [editorOptions]="{ maxLength: 12 }">
                    <dxi-validation-rule type="stringLength" [max]="12"></dxi-validation-rule>
                  </dxi-item>
                </dxi-item>
                </dx-form>
              </div>
            </dxi-item>
            <dxi-item title="Factures" *ngIf="selectedViewId === ViewType.Facturation">
              <app-facture-list *ngIf="!isCreation" [clientId]="partenaireId"></app-facture-list>
            </dxi-item>
            <dxi-item title="Comptabilité" *ngIf="(selectedViewId === ViewType.General || selectedViewId === ViewType.Facturation)">
              <div *dxTemplate>
                <dx-form *ngIf="partenaire" class="form last plain-styled-form general-padding" [formData]="partenaire"
                labelMode="floating" [class.view-mode]="!isEditing" [readOnly]="!isEditing" [colCount]="2">
                <dxi-item itemType="group" caption="Informations financières" [colSpan]="2" colCount="2"
                  *ngIf="partenaire?.type != partenaireType.interne">
                  <dxi-item [label]="{text: 'Encours'}" editorType="dxNumberBox" [editorOptions]="{
                          format: { type: 'fixedPoint', precision: 0 },
                          readOnly: true,
                          value: encoursClient
                        }"></dxi-item>
                  <dxi-item dataField="plafondEncours" [label]="{text: 'Plafond encours'}" editorType="dxNumberBox"
                    [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item>
                  <!-- <dxi-item dataField="jourPaiement" [label]="{text: 'Jour paiement'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item> -->
                  <!-- <dxi-item dataField="nombreJourPaiement" [label]="{text: 'Nombre jour paiement'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item> -->
                  <!-- <dxi-item dataField="debutPeriodePaiement" [label]="{text: 'Début période paiement'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item> -->
                  <!-- <dxi-item dataField="habitudePaiement" [label]="{ text: 'Habitude de paiement' }">
                        <dxi-validation-rule type="stringLength" [max]="150"></dxi-validation-rule>
                      </dxi-item> -->
                  <dxi-item dataField="partenaireFactureId" [label]="{text: 'Partenaire facturé'}"
                    editorType="dxSelectBox" [editorOptions]="{
                          dataSource: partenaireDataSource,
                          searchEnabled: true,
                          displayExpr: 'intitule',
                          valueExpr: 'id',
                          showClearButton: true,
                          searchExpr: ['intitule', 'codeBo'],
                          showDataBeforeSearch: true
                        }"></dxi-item>
                  <dxi-item dataField="partenaireParentId" [label]="{text: 'Partenaire parent'}"
                    editorType="dxSelectBox" [editorOptions]="{
                          dataSource: partenaireDataSource,
                          searchEnabled: true,
                          displayExpr: 'intitule',
                          valueExpr: 'id',
                          showClearButton: true,
                          searchExpr: ['intitule', 'codeBo'],
                          showDataBeforeSearch: true
                        }"></dxi-item>
                  <dxi-item dataField="controlEncours" [label]="{visible: false}" editorType="dxCheckBox"
                    [editorOptions]="{text: 'Contrôle encours'}"></dxi-item>
                  <dxi-item dataField="encoursNegatifAutorise" [label]="{visible: false}" editorType="dxCheckBox"
                    [editorOptions]="{text: 'Encours négatif autorisé'}"></dxi-item>
                  <dxi-item dataField="soumisTva" [label]="{visible: false}" editorType="dxCheckBox"
                    [editorOptions]="{text: 'Soumis à la TVA'}"></dxi-item>
                  <dxi-item dataField="facturationHT" [label]="{visible: false}" editorType="dxCheckBox"
                    [editorOptions]="{text: 'Facturé en HT'}"></dxi-item>
                </dxi-item>
                <!-- Partie Client -->
                <dxi-item itemType="group" [caption]="partenaire?.type != partenaireType.interne ? 'Client' : 'Compta'"
                  [colSpan]="1">
                  <dxi-item dataField="estClient" [label]="{visible: false}" editorType="dxCheckBox"
                    [editorOptions]="{text: 'Client'}" *ngIf="partenaire?.type != partenaireType.interne"></dxi-item>
                  <dxi-item itemType="group"
                    [disabled]="partenaire?.type == partenaireType.externe && !partenaire?.estClient">
                    <dxi-item dataField="codeBoClient" [label]="{ text: 'Code BackOffice client'}"
                      *ngIf="partenaire?.type != partenaireType.interne"></dxi-item>
                    <dxi-item dataField="compteGeneralClient"
                      [editorOptions]="{ maxLength: longueurCompte, format: { type: 'fixedPoint', precision: 0 }  }"
                      caption="Compte collectif client">
                      <dxi-validation-rule type="stringLength" [min]="longueurCompte" [max]="longueurCompte"
                        ignoreEmptyValue="true" message="{{ messageLongueurCompteInvalide }}">
                      </dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="compteAuxiliaireClient" [label]="{ text: 'Compte auxiliaire client' }"
                      [editorOptions]="{ maxLength: 20 }">
                      <dxi-validation-rule type="stringLength" [max]="20"
                        message="Le nom du compte auxiliaire ne peut dépasser 20 caractères."></dxi-validation-rule>
                    </dxi-item>
                  </dxi-item>
                </dxi-item>
                <!-- Partie Fournisseur -->
                <dxi-item itemType="group" caption="Fournisseur" [colSpan]="1"
                  *ngIf="partenaire?.type != partenaireType.interne">
                  <dxi-item dataField="estFournisseur" [label]="{visible: false}" editorType="dxCheckBox"
                    [editorOptions]="{text: 'Fournisseur'}"></dxi-item>
                  <dxi-item itemType="group" [disabled]="!partenaire?.estFournisseur">
                    <dxi-item dataField="codeBoFournisseur" [label]="{ text: 'Code BackOffice fournisseur' }"
                      [editorOptions]="{ maxLength: 36 }"></dxi-item>
                    <dxi-item dataField="compteGeneralFournisseur"
                      [editorOptions]="{ maxLength: longueurCompte, format: { type: 'fixedPoint', precision: 0 }  }"
                      caption="Compte collectif fournisseur">
                      <dxi-validation-rule type="stringLength" [min]="longueurCompte" [max]="longueurCompte"
                        ignoreEmptyValue="true" message="{{ messageLongueurCompteInvalide }}">
                      </dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="compteAuxiliaireFournisseur"
                      [label]="{ text: 'Compte auxiliaire fournisseur' }" [editorOptions]="{ maxLength: 20 }">
                      <dxi-validation-rule type="stringLength" [max]="20"
                        message="Le nom du compte auxiliaire ne peut dépasser 20 caractères."></dxi-validation-rule>
                    </dxi-item>
                  </dxi-item>
                </dxi-item>
                <!-- Partie Informations bancaires -->
                <dxi-item itemType="group" caption="Informations bancaires" [colSpan]="1">
                  <dxi-item dataField="informationsBancaire.numeroCompte" [label]="{text: 'Numéro de compte'}"
                    [editorOptions]="{ maxLength: 120 }"></dxi-item>
                  <dxi-item dataField="informationsBancaire.codeGuichet" [label]="{text: 'Code guichet'}"
                    [editorOptions]="{ maxLength: 120 }"></dxi-item>
                  <dxi-item dataField="informationsBancaire.cleRib" [label]="{text: 'Clé RIB'}"
                    [editorOptions]="{ maxLength: 120 }"></dxi-item>
                </dxi-item>
                </dx-form>
              </div>
            </dxi-item>
            <!-- Contacts -->
            <dxi-item title="Contacts" [disabled]="isCreation"
              *ngIf="partenaire?.type != partenaireType.interne && selectedViewId === ViewType.General">
              <app-contact-list *ngIf="!isCreation" [clientId]="partenaireId"
                [heightToDeduce]="heightToDeduce"></app-contact-list>
            </dxi-item>
            <!-- Classifications -->
            <dxi-item title="Classifications" [disabled]="isCreation"
              *ngIf="partenaire?.type != partenaireType.interne && selectedViewId === ViewType.General">
              <app-classification-datagrid *ngIf="!isCreation"
                [objectType]="objectType.partenaire"
                [itemId]="partenaireId"
                [heightToDeduce]="heightToDeduce"
                [allowEdit]="true"
              ></app-classification-datagrid>
            </dxi-item>
            <!-- Prix -->
            <dxi-item title="Prix" [disabled]="isCreation" *ngIf="selectedViewId === ViewType.Facturation">
              <app-client-prix-list *ngIf="!isCreation" [clientId]="partenaireId"></app-client-prix-list>
            </dxi-item>
            <!-- Lieux de stockage -->
            <dxi-item title="Lieux de stockage" [disabled]="isCreation" *ngIf="selectedViewId === ViewType.Logistique">
              <!-- Partie Logistique -->
              <div *dxTemplate >
                <dx-list>
                  <dxi-item dataField="lieuParDefautId">
                    <div *dxTemplate>
                      <dx-select-box 
                      label="Lieu de stockage par défaut"
                        [dataSource] = "lieuStockageDataSource"
                        [searchEnabled] = true
                        [displayExpr] = lieuStockageDisplayExpr
                        valueExpr = "id"
                        showClearButton = true
                        [searchExpr] = "['intitule', 'codeBo']"
                        (showDataBeforeSearch) = true
                        [disabled] = isClientExterne()
                        width="180">
                      </dx-select-box>
                    </div> 
                  </dxi-item>
                </dx-list>
                <app-lieu-stockage-tree-list [partenaireId]="partenaireId"
                [enableActions]="canGererLieuxStockages && isModificationEnCours" [currentSiteId]="currentSiteId"
                [partenaireCodeBo]="partenaire?.codeBo" [heightToDeduce]="heightToDeduce"
                (onUpdateLieuStockage)="setLieuStockageDataSource()"></app-lieu-stockage-tree-list>
              </div>  
            </dxi-item>
            <!-- Connaissement -->
            <dxi-item title="Connaissements"
              *ngIf="selectedViewId === ViewType.Logistique && partenaire?.type != partenaireType.interne"
              [disabled]="isCreation">
              <app-connaissement-list [clientId]="partenaireId"></app-connaissement-list>
            </dxi-item>
            <dxi-item title="Fret local"
              *ngIf="selectedViewId === ViewType.Logistique && partenaire?.type != partenaireType.interne">
                <dx-form *ngIf="partenaire" class="form last plain-styled-form general-padding" [formData]="partenaire"
                labelMode="floating" [class.view-mode]="!isEditing" [readOnly]="!isEditing">

                <dxi-item itemType="group" [colSpan]="2" colCount="2">
                  <dxi-item dataField="estRevendeur" [label]="{ text: 'Est revendeur' }" editorType="dxSwitch"></dxi-item>
                </dxi-item>
  
                <dxi-item itemType="group" colCount="3">
                  <dxi-item dataField="transporteurPrefereId" [label]="{text: 'Transporteur préféré'}"
                    editorType="dxSelectBox" [editorOptions]="{
                dataSource: transporteurDataSource,
                searchEnabled: true,
                displayExpr: 'designation',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['designation', 'codeBo', 'codeInternational'],
                showDataBeforeSearch: true
              }"></dxi-item>
                  <dxi-item dataField="lieuDebarquementPrivilegieId" [label]="{text: 'Lieu de débarquement préféré'}"
                    editorType="dxSelectBox" [editorOptions]="{
                dataSource: lieuDebarquementDataSource,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['intitule'],
                showDataBeforeSearch: true
              }"></dxi-item>
                </dxi-item>

                <dxi-item>
                  <div *dxTemplate>
                    <app-partenaire-fret-local-settings-form
              [fretLocalSettings]="partenaire?.fretLocalSettings" [formIsReadOnly]="!isEditing"
              [motifRefusDemande]="motifRefusDemande"
              [typeUtilisateur]="typeUtilisateurRuunui">
              </app-partenaire-fret-local-settings-form>
                  </div>
                </dxi-item>
                  
                
                  
              </dx-form>
            </dxi-item>

            <!-- Notes -->
            <dxi-item title="Notes" colCount="4">
              <div *dxTemplate>
                <dx-text-area *ngIf="partenaire" [(value)]="partenaire.notes" [inputAttr]="{ 'aria-label': 'Notes' }" [height]="160"
                [readOnly]="!isEditing"></dx-text-area>
              </div>
            </dxi-item>
            <!-- Documents -->
            <dxi-item title="Documents" [disabled]="isCreation">
              <div *dxTemplate>
                <app-document-list [objectId]="partenaireId" [objectType]="objectType.partenaire"></app-document-list>
              </div>
            </dxi-item>
            <!-- Caractéristique -->
            <dxi-item title="Caractéristiques" [disabled]="isCreation">
              <div *dxTemplate>
                <app-gestion-caracteristiques *ngIf="!isCreation" [objectId]="partenaireId"
                [objectType]="objectType.partenaire" [heightToDeduce]="heightToDeduce"></app-gestion-caracteristiques>
              </div>
            </dxi-item>
          </dx-tab-panel>
        </div>
      </div>
    </div>
  </div>
</dx-scroll-view>

<form-popup [(visible)]="isAddPartenairePopupOpened" (save)="onClickSaveNewPartenaire()" titleText="Nouveau Partenaire">
  <app-partenaire-new-form *ngIf="isAddPartenairePopupOpened"></app-partenaire-new-form>
</form-popup>
<!-- <div class="form-container" *ngIf="partenaire">
          <dx-box direction="row" width="100%" align="center" crossAlign="center">
            <div *ngIf="(!isCreation && encoursClient != null) && partenaire?.type != partenaireType.interne"
              style="font-size: 18px;">
              Encours : {{ encoursClient | number}} F
            </div>

            <dxi-item [ratio]="1"></dxi-item>
          </dx-box>


          <dx-box style="margin-bottom: 12px;" direction="row" width="100%">
            <dxi-item [ratio]="1">
              <div>
                <dx-button *ngIf="!isCreation && isModificationEnCours" class="action-button" type="success"
                  text="Enregistrer" (onClick)="onUpdatePartenaire()" style="margin-right: 10px;"></dx-button>
                <dx-button *ngIf="!isCreation && isModificationEnCours" class="action-button" type="danger"
                  text="Annuler" (onClick)="onCancelUpdate()"></dx-button>
                <dx-button *ngIf="isCreation" class="action-button" type="success" text="Créer"
                  (onClick)="onCreateNewPartenaire()"></dx-button>
              </div>
            </dxi-item>

          </dx-box>
          <dx-form #form [formData]="partenaire" [readOnly]="!isCreation && !isModificationEnCours" labelLocation="top"
            [showColonAfterLabel]="false">

          </dx-form>
        </div> -->